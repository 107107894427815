* {
  box-sizing: border-box
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemfont, 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: #eff3f7;
  height: 100%;
}